import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start

import {TextField, styled, Grid, FormControl, Select, MenuItem, Dialog} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ position:"relative", display:'flex',justifyContent:'center' }}>
          <Box 
          style ={{
              width: "100%", 
              }}
          >
            <img src = {this.state.backImage} 
            style={{             
                width: "100%",         
                objectFit: "cover", 
                position:'relative'    
              }}/>
          </Box>
          <Box 
          sx={{
            backgroundColor: "#FEFEFE26",
            width: { xs: "40px", sm: "48px", md: "54px" },
            height: { xs: "40px", sm: "48px", md: "54px" }, 
            top: { xs: "20px", sm: "30px", md: "50px" }, 
            left: { xs: "20px", sm: "30px", md: "50px" }, 
            gap: "0px",
            borderRadius: "14px",
            opacity: "0px",
            boxShadow: "-5px 4px 26px 0px #0000001A",
            position: "absolute",
          }}>
            <Box 
            sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "8px", sm: "10px", md: "14px" }, 
            }}
            >
              <img src={this.state.arrowImage}/>
            </Box>
          </Box>
          <MainBoxTextArea>
            <Box>
              <Typography 
              style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "30px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  letterSpacing: "-0.03em",
                  padding:"5px",
              }}
              >
                Find the Ideal Haven for Your Horse
              </Typography>
            </Box>  
            <Box>
              <Typography 
              style={{
                margin:"10px",
                color: "#FFFFFF",
                fontFamily: "Spartan",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "30px",
                letterSpacing: "-0.02em",
                textAlign: "center", 
              }}>
                Explore trusted, short-term stabling <br/> solutions with ease.
              </Typography>
            </Box>         
          </MainBoxTextArea>
        </Grid>
        <RightGrid item xs={12} sm={12} md={6} lg={6} sx={{ position:"relative", justifyContent:'center' }}>
          <Box
          style={{
            width: "520px",
            height: "100px",
            top: "81px",
            left: "822px",
            gap: "12px",
          }}>
            <Box 
            style={{
              width: "518px",
              height: "40px",
            }}>
              <Typography 
              style={{
                fontFamily: "Spartan",
                fontSize: "30px",
                fontWeight: 700,
                lineHeight: "40px",
                letterSpacing: "-0.03em",
                textAlign: "left",
                color: "#003249",
              }}>
                Get in touch
              </Typography>
            </Box>
            <Box 
            style={{
              width: "521px",
              height: "48px",
            }}>
              <Typography 
              style={{
                fontFamily: "Spartan",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                marginTop: "12px",
                color: "#475569",
              }}>
              We're here to help! If you have any questions, feedback, or <br/> need assistance, please reach out to us.
              </Typography>
            </Box>
          </Box>
          <Box 
          style={{
            width: "520px",
            height: "373px",
            top: "228px",
            left: "822px",
          }}>
            <Box 
            style={{
              width: "520px",
              height: "82px",
            }}>
              <Typography 
              style={{
                fontFamily: "Spartan",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "22px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                marginTop: "47px",
                color: "#64748B",
              }}>
              Email address
              </Typography>
              <TextField 
              onChange={this.handleEmailChange}
              value={this.state.email}
              data-test-id="handleEmailChange"
              error={!!this.state.emailError}
                style={{
                  width: "100%",
                  height: "56px",
                  borderRadius: "8px", 
                  marginTop: "4px",
                  border: "1px",
                }}
                placeholder="mail@example.com"
                type="email"
                name="email"
                variant="outlined"
                required
              />
              {this.state.emailError!="" && 
                <p style={{
                  color: "#DC2626",
                  fontFamily: "Spartan !important",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "-0.02em",
                  textAlign: "left",
                  marginTop: "0px",
                  }}>
                  {this.state.emailError}
                </p>
              }
            </Box>
            <Box 
            style={{
              width: "520px",
              height: "82px",
            }}>
              <Typography 
              style={{
                fontFamily: "Spartan",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "22px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                marginTop: "16px",
                color: "#64748B",
              }}>
              Issue type
              </Typography>
              <FormControl variant="outlined" required
              style={{ 
                width: "520px",
                height: "56px",
                borderRadius: "8px",
                border: "1px",
                marginTop: "4px",
              }}>
                <Select value={this.state.issueType} 
                onChange={this.handleIssueType} 
                data-test-id="handleIssueType"
                style={{
                  color: this.state.issueType == "Select your issue type" ? "#94A3B8" : "#000000",
                  fontFamily: "Spartan",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "-0.02em",
                  textAlign: "left",
                 }}>
                  <MenuItem value='Select your issue type' style={{display:'none'}}>Select your issue type</MenuItem>
                  <MenuItem value="general_inquiry">general_inquiry</MenuItem>
                  <MenuItem value="technical_support">technical_support</MenuItem>
                  <MenuItem value="issue_with_booking">issue_with_booking</MenuItem>
                  <MenuItem value="feedback">feedback</MenuItem>
                  <MenuItem value="other">other</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
            style={{
              width: "520px",
              height: "177px",
            }}>
              <Typography
              style={{
                fontFamily: "Spartan",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "22px",
                letterSpacing: "-0.02em",
                textAlign: "left",
                marginTop: "16px",
                color: "#64748B",
              }}>
              Detail of the query
              </Typography>
              <TextField 
              onChange={this.handleDescription}
              value={this.state.description}
              data-test-id="handleDescription"
              style={{
                width: "520px",
                height: "151px",
                borderRadius: "8px",
                border: "1px",
                marginTop: "4px",
              }}
              placeholder="Tell us more about your query..."
              type="query"
              name="query"
              variant="outlined"
              required
              />
            </Box>
          </Box>
          <Box
          style={{
            width: "520px",
            height: "56px",
          }}>
            <Button disabled={this.state.email && this.state.issueType && this.state.description ? false: true} 
            onClick={this.handleButtonClick} 
            data-test-id="handleButtonClick"
            style={{
              backgroundColor: this.state.email && this.state.issueType && this.state.description ? "#003249":'#94A3B8',
              color: "#FFFFFF",
              width: "520px",
              height: "56px",
              borderRadius: "8px",
              fontFamily: "Spartan",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
              letterSpacing: "-0.03em",
              textAlign: "left",
              textTransform: "none",
              marginTop: "144px",
            }}>
              Submit
            </Button>
          </Box>
        </RightGrid>
      </Grid>
      <CustomDialog style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} 
        open={this.state.linkSent} 
        onClose={this.handleClose}
        data-test-id="handleClose">
            <Box
            style={{
                color: "#FFFFFF",
                padding:'50px 39px'
                }}
            >
                <Box
                style={{
                    display:'flex',
                    justifyContent:'center'
                }}>
                    <img src={this.state.checkButton}/>
                </Box>
                <Box 
                style={{
                    marginTop: "62px",
                    }}>
                    <Box>
                        <Typography 
                        style={{
                            fontFamily: "Spartan",
                            fontSize: "30px",
                            fontWeight: 700,
                            lineHeight: "40px",
                            letterSpacing: "-0.03em",
                            textAlign: "center",
                            color: "#003249",
                        }}>
                            Inquiry sent!
                        </Typography>
                    </Box>
                    <Box>
                        <Typography 
                        style={{
                          marginTop: "12px",
                            fontFamily: "Spartan",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "-0.02em",
                            textAlign: "center",
                            color: "#475569",
                            width: "496px",
                        }}>
                           Congratulations, Your inquiry has been sent to <br/> our support team
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Button onClick={this.handleButtonClick}
                    style={{
                        gap: "8px",
                        marginTop: "54px",
                        width: "100%",
                        padding:"16px",
                        color: "#FFFFFF",
                        backgroundColor: "#003249",
                        borderRadius: "8px",
                        fontFamily: "Spartan",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        letterSpacing: "-0.03em",
                        textTransform:'none',
                        }}>
                          Done
                    </Button>
                </Box>
            </Box>
        </CustomDialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start
const CustomDialog=styled(Dialog)({
  "& .MuiDialog-paper":{
    borderRadius: "15px"
  }
})

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MainBoxTextArea =styled(Box)({
  position: "absolute",
  background: "#FFFFFF33",
  bottom:38,
  gap: "0px",
  borderRadius: "5px",
  opacity: "0px",
  backdropFilter: "blur(5px)",
  padding:"1rem",
  textAlign:'center'
})

const RightGrid = styled(Grid)({
  position: "relative",
  justifyContent: "center",
  paddingTop: "81px",
  paddingLeft: "112px",
  paddingRight: "98px",
  paddingBottom: "91px",
})

// Customizable Area End
