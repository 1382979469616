import React from "react";

// Customizable Area Start
import {Typography, Box, Grid, TextField,styled, Button, InputAdornment,IconButton, Checkbox} from '@mui/material';
import { StyleSheet } from "react-native";

const MainBoxTextArea=styled(Box)({
  position: "absolute",
  background: "#FFFFFF33",
  bottom:38,
  borderRadius: "5px",
  backdropFilter: "blur(5px)",
  padding:"1rem",
  textAlign:'center'
})
const RightGrid=styled(Grid)
({
    height: "100vh", backgroundColor: "#FFFFFF", 
    justifyContent:'center', position:'relative', paddingTop:'70px', paddingBottom:'52px',paddingLeft:'112px', paddingRight:'98px',
    '@media only screen and (max-width: 1036px)':{
        paddingLeft:'50px',
        paddingRight:'50px'
    }

})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Grid container alignItems="center" justifyContent="center" style={{height:'100vh'}}>
            <Grid item xs={12} sm={6} sx={{ position:"relative", display:'flex',justifyContent:'center' }}>
                <Box 
                style ={{
                    display: "flex",
                    width: "100%", 
                    }}
                >
                    <img src = {this.state.bgImage} 
                    style={{             
                        opacity: "0px",         
                        objectFit: "cover", 
                        position:'relative'    
                      }}/>
                </Box>
                <Box 
                sx={{
                    backgroundColor: "#FEFEFE26",
                    width: { xs: "40px", sm: "48px", md: "54px" },
                    height: { xs: "40px", sm: "48px", md: "54px" }, 
                    top: { xs: "20px", sm: "30px", md: "50px" }, 
                    left: { xs: "20px", sm: "30px", md: "50px" }, 
                    gap: "0px",
                    borderRadius: "14px",
                    opacity: "0px",
                    boxShadow: "-5px 4px 26px 0px #0000001A",
                    position: "absolute",
                }}
                >
                    <Box 
                    sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: { xs: "8px", sm: "10px", md: "14px" }, 
                    }}
                    >
                    <img src={this.state.arrowButton}/>
                    </Box>
                </Box>
                <MainBoxTextArea>
                    <Box>
                    <Typography 
                    style={{
                        color: "#FFFFFF",
                        fontFamily: "Spartan",
                        fontSize: "30px",
                        fontWeight: 700,
                        lineHeight: "40px",
                        letterSpacing: "-0.03em",
                        padding:"5px",
                    }}
                    >
                      Find the Ideal Haven for Your Horse                    
                      </Typography>
                    </Box>  
                    <Box>
                    <Typography 
                    style={{
                        margin:"10px",
                        color: "#FFFFFF",
                        fontFamily: "Spartan ",
                        fontSize: "20px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        letterSpacing: "-0.02em",
                        textAlign: "center", 
                    }}
                    >
                    Explore trusted, short-term stabling <br/> solutions with ease.
                    </Typography>
                    </Box>         
                </MainBoxTextArea>
            </Grid>
            <RightGrid item xs={12} sm={6}>
              <Box>
                <Box>
                  <Typography
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "30px",
                      fontWeight: "700",
                      lineHeight: "40px",
                      letterSpacing: "-0.03em",
                      textAlign: "left",
                      color: "#003249",
                    }}
                  >
                   Welcome back!
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#475569",
                      marginTop: "12px",
                    }}
                  >
                    Don't have an account?
                    <span
                    onClick={this.navigateToSignUpPage}
                    data-test-id="navigateToSignUpPage"
                      style={{
                        cursor: "pointer",
                        color: "#003249",
                        fontFamily: "Spartan ",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        letterSpacing: "-0.03em",
                        textAlign: "left",
                        textDecoration:"underline",
                        marginLeft: "8px",
                      }}
                    >
                      Register now
                    </span>
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop:"70px",
                    borderRadius: "8px",
                  }}
                > 
                  <Typography
                    variant="h6"
                    style={{
                      fontFamily: "Spartan ",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "22px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#64748B",
                      marginTop: "16px",
                    }}
                  >
                    Email address
                  </Typography>
                  <TextField 
                  onChange={this.handleEmailChange}
                  value={this.state.email}
                  data-test-id="handleEmailChange"
                  error={!!this.state.emailError}
                    style={{
                      borderRadius: "8px",
                      border: "1px", 
                      marginTop: "6px",
                    }}
                    placeholder="mail@example.com"
                    type="email"
                    name="email"
                    variant="outlined"
                    fullWidth
                  />
                  {this.state.emailError!="" && 
                    <p style={{
                      color: "#DC2626",
                      fontFamily: "Spartan ",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      marginTop: "10px",
                      }}>
                      {this.state.emailError}
                    </p>
                  }
                  <Typography
                    style={{
                      fontFamily: "Spartan ",
                      fontSize: "14px",
                      lineHeight: "22px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#64748B",
                      marginTop: "16px",
                      fontWeight: 600,
                    }}
                  >
                    Password
                  </Typography>
                  <TextField onChange={this.handlePasswordChange}
                  value={this.state.password}
                  data-test-id="handlePasswordChange"
                  error={!!this.state.passwordError}
                    style={{
                      borderRadius: "8px",
                      border: "1px ",
                      marginTop: "6px",
                    }}
                    placeholder="Enter your password"
                    type={this.state.enablePasswordField?'password':'text'}
                    name="password"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {!this.state.enablePasswordField ? (
                          <img src={this.state.passwordVisible}  />
                        ) : (
                          <img src={this.state.passwordInVisible} />
                        )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.passwordError!=""&&
                    <p style={{
                      color: "#DC2626",
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      marginTop: "10px",
                      }}>
                      {this.state.passwordError}
                    </p>
                  }
                  
                 
                <Box 
                  style={{
                    display:'flex',
                    justifyContent:'flex-end',
                    width:'100%'}}>
                  <Typography
                    onClick={this.navigateToForgotPassword}
                    data-test-id= "navigateToForgotPassword"
                    style={{
                      cursor: "pointer",
                      fontFamily: "Spartan",
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "22px",
                      letterSpacing: "-0.02em",
                      color: "#64748B",
                      marginTop: "12px",
                    }}
                  >
                    Forgot your password?
                  </Typography>
                  </Box>
                </Box>
                <Box style={{display:"flex", justifyContent:"center", marginTop:"288px",}}>
                <Checkbox
                    checked={this.state.checked}
                    onChange={(e:any)=>this.handleCheckboxChange(e)}
                    data-test-id="handleCheckboxChange"
                    style={{
                      color: "#003249",
                      width: "24px",
                      height: "24px",
                      marginRight: "6px",
                    }}
                  />
                  <Typography 
                  style={{
                    fontFamily:"Spartan ",
                    fontSize:"12px",
                    lineHeight:"20px",
                    letterSpacing:"-2%",
                    color: "#475569",
                  }}
                  >
                    By creating an account you accept Haul-Inn's 
                    <span 
                    onClick={this.navigateToTermsConditions}
                    data-test-id="navigateToTermsConditions"
                    style={{
                      cursor: "pointer",
                      fontFamily: "Spartan !important",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "22px",
                      letterSpacing: "-0.02em",
                      textDecoration:"underline",
                      color: "#475569",
                      marginLeft: "6px",
                      }}
                      >terms and conditions</span>
                  </Typography>
                </Box>
                <Box>
                  <Button disabled={this.state.email && this.state.password && this.state.checked ? false: true}
                  style={{
                    background:this.state.email && this.state.password && this.state.checked ? "#003249":'#F1F5F9',
                    width:"100%", 
                    height:"56px", 
                    padding:"16px",
                    color: this.state.email && this.state.password && this.state.checked ? "#fff":"#000",
                    borderRadius: "8px",
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "-0.03em",
                    textTransform:'none',
                    marginTop: "16px",
                    }}
                    onClick={this.loginApi}
                    data-test-id = "loginApi">
                    Login
                  </Button>
                </Box>
              </Box>
            </RightGrid>
          </Grid>      
    
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
