// Customizable Area Start

import React from "react";

import {Typography, Box, Grid, TextField,styled, FormControl, FormLabel, Button, Dialog, InputAdornment,IconButton, Checkbox} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const MainBoxTextArea=styled(Box)({
  position: "absolute",
  background: "#FFFFFF33",
  bottom:38,
  borderRadius: "5px",
  backdropFilter: "blur(5px)",
  padding:"1rem",
  textAlign:'center'
})

const RightGrid = styled(Grid)({
  position: "relative",
  justifyContent: "center",
  paddingTop: "74px",
  paddingLeft: "112px",
  paddingRight: "100px",
})

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: "Spartan",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  letterSpacing: "-0.02em",
  textAlign: "left",
  color: "#64748B",
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  "& .react-tel-input .special-label": {
      display:'none'
  },
  "& .react-tel-input .form-control": {
      display: 'none',
      width: 'calc(100% - 46px)',
  },
  // "& .react-tel-input .selected-flag .flag": {
  //     marginTop:"-10px",
  //     marginLeft:'-10px'
  // },
}));

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isUppercaseValid,
      isLengthValid,
      isNumberValid,
      isSpecialCharValid,
    } = this.state;
    return (
      <>
      <MainWrapper>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ position:"relative", display:'flex',justifyContent:'center', backgroundColor: "#000" }}>
            <Box 
            style ={{
              display: "flex",
              width: "100%",
              height: "100%",
              backgroundColor: "#000"
            }}>
              <img src = {this.state.bgImage} 
              style={{ 
                objectFit: "cover", 
                position:'relative',              
                width: "100%",   
                height: "100%",      
              }}/>
            </Box>
            <Box 
            sx={{
              backgroundColor: "#FEFEFE26",
              width: { xs: "40px", sm: "48px", md: "54px" },
              height: { xs: "40px", sm: "48px", md: "54px" }, 
              top: { xs: "20px", sm: "30px", md: "50px" }, 
              left: { xs: "20px", sm: "30px", md: "50px" }, 
              borderRadius: "14px", opacity: "0px", gap: "0px",
              position: "absolute", boxShadow: "-5px 4px 26px 0px #0000001A",
            }}>
              <Box 
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: { xs: "8px", sm: "10px", md: "14px" }, 
              }}
              >
                <img src={this.state.arrowImage}/>
              </Box>
            </Box>
            <MainBoxTextArea>
              <Box>
                <Typography 
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "30px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  letterSpacing: "-0.03em",
                  paddingTop:"28px",
                  paddingBottom: "10px",
                  paddingLeft: "70px",
                  paddingRight: "70px",
                }}>
                  Find Your Horse's Perfect Stay
                </Typography>
              </Box>  
              <Box>
                <Typography 
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "30px",
                  letterSpacing: "-0.02em",
                  textAlign: "center", 
                  paddingBottom: "32px",
                  paddingLeft: "85px",
                  paddingRight: "85px",
                }}>
                  Discover reliable, temporary stabling <br/> options effortlessly.
                </Typography>
              </Box>         
            </MainBoxTextArea>
          </Grid>
          <RightGrid item xs={12} sm={6}>
            <Box>
              <Box 
              style={{
                width: "520px",
                height: "76px",
              }}>
                <Typography 
                style={{
                  fontFamily: "Spartan",
                  fontSize: "30px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  letterSpacing: "-0.03em",
                  textAlign: "left",
                  color: "#003249",
                }}>
                  Create your account
                </Typography>
                <Typography 
                style={{
                  fontFamily: "Spartan",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "-0.02em",
                  textAlign: "left",
                  color: "#475569",
                  marginTop: "12px",
                }}>
                  Already have an account?
                  <span 
                  onClick={this.navigateToLoginPage}
                  data-test-id="navigateToLoginPage"
                  style={{
                    cursor: "pointer",
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: "-0.03em",
                    textAlign: "left",
                    textDecoration: "underline",
                    marginLeft: "8px",
                    color: "#003249",
                  }}>
                    Log in
                  </span>
                </Typography>
              </Box>
              <Box 
              style={{
                width: "520px",
                height: "492px",
                gap: "16px",
                marginTop: "40px",
              }}>
                <Box 
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%"
                }}>
                  <Box style={{display: "flex", flexDirection: "column", justifyContent: "left", width: "256px"}}>
                    <Typography 
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "22px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#64748B",
                    }}>
                      First name
                    </Typography>
                    <TextField
                    onChange={this.handleFirstName}
                    value={this.state.firstName}
                    data-test-id="handleFirstName" 
                    style={{
                      borderRadius: "8px",
                      border: "1px", 
                      marginTop: "4px",
                      color: "#64748B",
                      fontFamily: "Spartan",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "-0.02em",
                      }}
                      placeholder="Enter your first name"
                      type="first name"
                      name="first name"
                      variant="outlined"
                    />
                  </Box>
                  <Box style={{display: "flex", flexDirection: "column", justifyContent: "left", marginLeft: "8px", width: "256px"}}>
                    <Typography
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "22px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#64748B",
                    }}>
                      Last name
                    </Typography>
                    <TextField 
                    onChange={this.handleLastName}
                    value={this.state.lastName}
                    data-test-id="handleLastName"
                      style={{
                        borderRadius: "8px",
                        border: "1px", 
                        marginTop: "4px",
                        color: "#64748B",
                        fontFamily: "Spartan",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "-0.02em",
                      }}
                      placeholder="Enter your last name"
                      type="last name"
                      name="last name"
                      variant="outlined"
                    /> 
                  </Box>
                </Box>
                <Box 
                style={{
                  width: "520px",
                  height: "82px",
                }}>
                  <Typography 
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "22px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    marginTop: "16px",
                    color: "#64748B",
                  }}>
                    Email address
                  </Typography>
                  <TextField 
                    onChange={this.handleEmailChange}
                    value={this.state.email}
                    data-test-id="handleEmailChange"
                    error={!!this.state.emailError}
                    style={{
                      borderRadius: "8px",
                      border: "1px", 
                      marginTop: "4px",
                      color: "#64748B",
                      fontFamily: "Spartan",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "-0.02em",
                    }}
                    placeholder="mail@example.com"
                    type="email"
                    name="email"
                    variant="outlined"
                    fullWidth
                  />
                  {this.state.emailError!="" && 
                      <p style={{
                        color: "#DC2626",
                        fontFamily: "Spartan !important",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        marginTop: "-2px",
                        }}>
                        {this.state.emailError}
                      </p>
                    }
                </Box>
                <Box style={{width: "520px", height: "82px", marginTop: "16px"}}>
                  <FormControl fullWidth >
                    <StyledFormLabel htmlFor="phoneNumber">
                      Phone number
                    </StyledFormLabel>
                      <PhoneInput
                        inputProps={{
                          id: "phoneNumber",
                          name: "phoneNumber",
                        }}
                        data-test-id="handlePhoneNumber"
                        country={"de"}
                        value={this.state.phone}
                        onChange={this.handlePhoneNumber}
                        enableSearch={true}
                        disableSearchIcon={false}
                        countryCodeEditable={true}
                        inputStyle={{
                          marginTop: "2px", 
                          width: "100%",
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "4px",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "24px",
                          fontFamily: "Spartan",
                          letterSpacing: "-0.02em",
                        }}
                      />
                      {this.state.phoneError!="" && 
                      <p style={{
                        color: "#DC2626",
                        fontFamily: "Spartan !important",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                        marginTop: "-2px",
                        }}>
                        {this.state.phoneError}
                      </p>
                    }
                  </FormControl>
                </Box>
                <Box style={{width: "520px", height: "82px", marginTop: "16px"}}>
                  <Typography 
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "22px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: "#64748B",
                     marginTop: "20px"
                  }}>
                    Password
                  </Typography>
                  <TextField 
                  onChange={this.handleNewPassword}
                  value={this.state.newPassword}
                  data-test-id="handleNewPassword"
                  error={!!this.state.newPasswordError}
                  style={{
                    borderRadius: "8px",
                    border: "1px", 
                    marginTop: "4px",
                    color: "#64748B",
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "-0.02em",
                  }}
                  placeholder="Enter your password"
                  type={this.state.enableNewPasswordField?'password':'text'}
                  name="password"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.handleShowNewPassword}
                        data-test-id= "handleShowNewPassword"
                        edge="end"
                      >
                        {!this.state.enableNewPasswordField ? (
                          <img src={this.state.passwordVisible}  />
                        ) : (
                        <img src={this.state.passwordInVisible} />
                        )}
                      </IconButton>
                    </InputAdornment>
                    ),
                  }}
                  />
                </Box>
                <Box style={{ width: "520px",height: "82px", marginTop: "16px",}}>
                  <Typography 
                  style={{
                    lineHeight: "22px", letterSpacing: "-0.02em",
                    textAlign: "left", color: "#64748B",fontFamily: "Spartan",
                    fontSize: "14px", fontWeight: 700,
                  }}
                  >
                    Confirm password
                  </Typography>
                  <TextField 
                  onChange={this.handleConfirmPassword}
                  value={this.state.confirmPassword}
                  data-test-id="handleConfirmPassword"
                  error={!!this.state.confirmPasswordError}
                  style={{
                    borderRadius: "8px",
                    border: "1px", 
                    marginTop: "4px",
                    color: "#64748B",
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "-0.02em",
                  }}
                  placeholder="Confirm your password"
                  type={this.state.enableConfirmPasswordField?'password':'text'}
                  name="confirm password"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                        onClick={this.handleShowConfirmPassword}
                        data-test-id="handleShowConfirmPassword"
                        edge="end"
                        >
                          {!this.state.enableConfirmPasswordField ? (
                            <img src={this.state.passwordVisible}  />
                          ) : (
                          <img src={this.state.passwordInVisible} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  />
                  <Typography 
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: "#DC2626",
                    }}>
                    {this.state.confirmPasswordError}
                  </Typography>
                </Box>  
              </Box>
              <Box style={{ width: "520px", height: "110px", marginTop: "6px" }}>
                <Box style={{ display: "flex", width: "186px", height: "18px"}}>
                  <Typography 
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: 700,
                    lineHeight: "18px",
                    textAlign: "left",
                    color: "#64748B",
                    letterSpacing: "-0.02em",
                  }}>
                    Your password must contain
                  </Typography>
                </Box>
                <Box style={{ width: "520px", height: "84px", marginTop: "8px" }}>
                  <Box style={{ display: "flex", alignItems: "center", marginTop: "4px"}}>
                    {isUppercaseValid ? <CheckCircleOutlineIcon style={{color: '#059669', marginRight: '8px'}} /> : <HighlightOffIcon style={{ color: 'Red', marginRight: '8px' }} />}
                    <Typography 
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#475569",
                    }}
                    >
                      1 upper case letter
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", marginTop: "4px"}}>
                    {isLengthValid ? <CheckCircleOutlineIcon style={{color: '#059669', marginRight: '8px' }} /> : <HighlightOffIcon style={{color: 'Red', marginRight: '8px' }} />}
                    <Typography 
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#475569",
                    }}
                    >
                      Between 8 and 20 characters
                    </Typography>
                  </Box> 
                  <Box style={{ display: "flex", alignItems: "center", marginTop: "4px",}}>
                    {isNumberValid ? <CheckCircleOutlineIcon style={{color: '#059669', marginRight: '8px', }} /> : <HighlightOffIcon style={{color: 'Red', marginRight: '8px' }} />}
                    <Typography 
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#475569",
                    }}
                    >
                      1 or more numbers
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", marginTop: "4px"}}>
                    {isSpecialCharValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px' }} /> : <HighlightOffIcon style={{color: 'Red', marginRight: '8px' }} />}
                    <Typography 
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "-0.02em",
                      textAlign: "left",
                      color: "#475569",
                    }}
                    >
                      1 or more special characters
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box 
              style={{
                display:"flex", 
                flexDirection: "column", 
                width: "520px", 
                height: "96px",
                margin: "49px",
              }}>
                <Box 
                style={{
                  display:"flex", 
                  flexDirection: "row", 
                  width: "495px",
                  height: "24px",
                }}>
                  <Checkbox
                    checked={this.state.checked}
                    onChange={(e:any)=>this.handleCheckboxChange(e)}
                    data-test-id="handleCheckboxChange"
                    style={{
                      color: "#003249",
                      width: "24px",
                      height: "24px",
                      marginRight: "6px",
                    }}
                  />
                  <Typography 
                  style={{
                    fontFamily:"Spartan !important",
                    fontSize:"12px",
                    lineHeight:"20px",
                    letterSpacing:"-2%",
                    color: "#475569",
                  }}
                  >
                    By creating an account you accept Haul-Inn's 
                  <span 
                  onClick={this.navigateToTermsConditions}
                  data-test-id="navigateToTermsConditions"
                  style={{
                    cursor: "pointer",
                    fontFamily: "Spartan !important",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "22px",
                    letterSpacing: "-0.02em",
                    textDecoration:"underline",
                    color: "#475569",
                    marginLeft: "6px",
                  }}>
                      terms and conditions
                    </span>
                  </Typography>
                </Box>
                <Box 
                style={{
                  marginTop: "16px",
                }}>
                  <Button disabled={this.state.firstName && this.state.lastName && this.state.email && this.state.phone && this.state.newPassword && this.state.confirmPassword && this.state.checked ? false: true}
                    style={{
                      background: this.state.firstName && this.state.lastName && this.state.email && this.state.phone && this.state.newPassword && this.state.confirmPassword && this.state.checked ? "#003249":'#94A3B8',
                      width:"520px", 
                      height:"56px", 
                      color: this.state.firstName && this.state.lastName && this.state.email && this.state.phone && this.state.newPassword && this.state.confirmPassword && this.state.checked ? "FFFFFF" : "#FFFFFF",
                      borderRadius: "8px",
                      fontFamily: "Spartan !important",
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      letterSpacing: "-0.03em",
                      textTransform:'none',
                    }}
                    onClick={this.createApi}
                    data-test-id = "createApi">
                      Create an account
                  </Button>
                </Box>
              </Box>
            </Box>
          </RightGrid>
        </Grid>
      </MainWrapper>
      <Dialog style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} 
        open={this.state.linkSent} 
        onClose={this.handleClose}
        data-test-id="handleClose">
            <Box
            style={{
                color: "#FFFFFF",
                padding:'50px 39px'
                }}
            >
                <Box
                style={{
                    display:'flex',
                    justifyContent:'center'
                }}>
                    <img src={this.state.dialogImage}/>
                </Box>
                <Box 
                style={{
                    marginTop: "62px",
                    }}>
                    <Box>
                        <Typography 
                        style={{
                            fontFamily: "Spartan",
                            fontSize: "30px",
                            fontWeight: "700",
                            lineHeight: "40px",
                            letterSpacing: "-0.03em",
                            textAlign: "center",
                            color: "#003249",
                        }}>
                            Check your email
                        </Typography>
                    </Box>
                    <Box>
                      <Typography 
                      style={{
                          fontFamily: "Spartan",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.02em",
                          textAlign: "center",
                          color: "#475569",
                          width: "496px",
                      }}>
                          A confirmation email has been sent to your registered email address
                      </Typography>
                    </Box>
                </Box>
                <Box>
                  <Button 
                  onClick={this.handleButtonClick}
                  data-test-id = "handleButtonClick"
                  style={{
                    gap: "8px",
                    marginTop: "72px",
                    width: "100%",
                    padding:"16px",
                    color: "#FFFFFF",
                    backgroundColor: "#003249",
                    borderRadius: "8px",
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: "-0.03em",
                    textTransform:'none',
                    }}>
                      Resend on email
                  </Button>
                </Box>
            </Box>
        </Dialog>
      </>
    );
  }

// Customizable Area End
}