import React from "react";

// Customizable Area Start
import {Typography, Box, Grid,styled, Button} from '@mui/material';
import {
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ActivityIndicator,
} from "react-native";
import CheckBox from "../../../components/src/CustomCheckBox";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { FlatList } from "react-native-gesture-handler";
import moment from "moment";

const MainBoxTextArea=styled(Box)({
  position: "absolute",
  background: "#FFFFFF33",
  bottom:38,
  gap: "0px",
  borderRadius: "5px",
  opacity: "0px",
  backdropFilter: "blur(5px)",
  padding:"1rem",
  textAlign:'center'
})
const RightGrid = styled(Grid)({
  position: "relative",
  justifyContent: "center",
  paddingTop: "74px",
  paddingLeft: "112px",
  paddingRight: "98px",
})
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ position:"relative", display:'flex',justifyContent:'center' }}>
          <Box 
          style ={{
              display: "flex",
              width: "100%", 
              }}
          >
              <img src = {this.state.backImage} 
              style={{             
                  width: "100%",         
                  objectFit: "cover", 
                  position:'relative'    
                }}/>
          </Box>
          <Box 
          sx={{
              backgroundColor: "#FEFEFE26",
              width: { xs: "40px", sm: "48px", md: "54px" },
              height: { xs: "40px", sm: "48px", md: "54px" }, 
              top: { xs: "20px", sm: "30px", md: "50px" }, 
              left: { xs: "20px", sm: "30px", md: "50px" }, 
              gap: "0px",
              borderRadius: "14px",
              opacity: "0px",
              boxShadow: "-5px 4px 26px 0px #0000001A",
              position: "absolute",
          }}
          >
              <Box 
              sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: { xs: "8px", sm: "10px", md: "14px" }, 
              }}
              >
              <img src={this.state.arrowImage}/>
              </Box>
          </Box>
          <MainBoxTextArea>
              <Box>
              <Typography 
              style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "30px",
                  fontWeight: 700,
                  lineHeight: "40px",
                  letterSpacing: "-0.03em",
                  paddingTop:"28px",
                  paddingBottom: "10px",
                  paddingLeft: "70px",
                  paddingRight: "70px",
              }}
              >
              Find Your Horse's Perfect Stay
              </Typography>
              </Box>  
              <Box>
              <Typography 
              style={{
                  color: "#FFFFFF",
                  fontFamily: "Spartan",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "30px",
                  letterSpacing: "-0.02em",
                  textAlign: "center", 
                  paddingBottom: "32px",
                  paddingLeft: "85px",
                  paddingRight: "85px",
              }}
              >
              Discover reliable, temporary stabling <br/> options effortlessly.
              </Typography>
              </Box>         
          </MainBoxTextArea>
        </Grid>
        <RightGrid item xs={12} sm={6}>
          <Box 
          style ={{
            width: "100%", 
          }}>
            <Box>
              <Typography 
              style = {{
                fontFamily: "Spartan",
                fontSize: "30px",
                fontWeight: 700,
                lineHeight: "40px",
                letterSpacing: "-0.03em",
                textAlign: "left",
                color: "#003249",
              }}>
              Terms and conditions
              </Typography>
            </Box>  
            <Box>
              <div style={{fontFamily:"Spartan", paddingTop: "17px"}} dangerouslySetInnerHTML={{ __html: this.state.termsConditionData}}/>
              <Button 
                style={{ 
                  display: "flex", 
                  width: "100%",
                  height: "56px",
                  gap: "8px",
                  borderRadius: "8px",
                  backgroundColor: "#003249",
                  marginTop: "650px",
                  marginBottom: "55px",
                }}>
                  <Typography
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: "-0.03em",
                    textAlign: "center",
                    textTransform: "none",
                    color:"#FFFFFF",
                  }}>
                    Accept
                  </Typography>
              </Button>
            </Box>
          </Box>
        </RightGrid>
      </Grid>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
  spinnerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    flex: 1,
    padding: 20,
    fontWeight: "600",
    fontSize: 22,
  },
  termsCondsTitle: {
    fontWeight: "600",
    marginBottom: 20,
    fontSize: 22,
  },
  scrollView: {
    height: "100%",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: 40,
    marginRight: 20,
  },
  termsCondsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    padding: 20,
  },
  button: {
    backgroundColor: "blue",
    marginLeft: 10,
    width: 120,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    alignSelf: "flex-end",
  },
  buttonLabel: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: 20,
  },
});
// Customizable Area End
